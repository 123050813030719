import {
  isChrome,
  isEdge,
  isFirefox,
  isIE,
  isOpera,
} from 'react-device-detect';
import SoRektAddress from './Backend/ContractData/SoRekt-address.json';
import SoRektAbi from './Backend/ContractData//SoRekt.json';
import SoRektAiAddress from './Backend/ContractData/SoRektAiNFT-address.json';
import SoRekAitAbi from './Backend/ContractData//SoRektAiNFT.json';
const ethers = require('ethers');

function findBrowserAndSetNavigation(setNavigation) {
  // console.log("Browser: ");
  // console.log(browserName, browserVersion);
  // console.log(isFirefox, isEdge, isSafari, isChrome, isIE);
  if (isFirefox)
    setNavigation(
      'https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/'
    );
  else if (isEdge || isIE)
    setNavigation(
      'https://microsoftedge.microsoft.com/addons/detail/metamask/ejbalbakoplchlghecdalmeeeajnimhm?hl=en-US'
    );
  else if (isChrome)
    setNavigation(
      'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn'
    );
  else if (isOpera)
    setNavigation(
      'https://addons.opera.com/en-gb/extensions/details/metamask-10/'
    );
  // else if(isBrave)
  else
    setNavigation(
      'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn'
    );
}
export async function web3Handler(
  setAccount,
  setProvider,
  setMsg,
  setIsModalOpen,
  setNavigation,
  setSoRekt
) {
  console.log(setSoRekt);
  if (
    window.ethereum &&
    window.ethereum.isMetaMask &&
    !window.ethereum.isBraveWallet
  ) {
    try {
      // const x = await handleSwitchNetwork();
      // if (x !== 1) {
      //   alert("Switch network");
      //   return;
      // }
      let chainId = await window.ethereum.request({ method: 'eth_chainId' });
      chainId = parseInt(chainId.toString(), 16);
      console.log('Chain id: ', chainId);
      if (chainId !== 5) {
        setMsg("Please Switch to Goerli Network");
        setIsModalOpen(true);
        return;
      }
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      });
      setAccount(accounts[0]);

      // Get provider from Metamask
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      // console.log(provider);
      setProvider(provider);
      // Set signer
      const signer = provider.getSigner();

      window.ethereum.on('chainChanged', (chainId) => {
        // window.location.reload();
      });

      window.ethereum.on('accountsChanged', async function (accounts) {
        setAccount(accounts[0]);
        await web3Handler();
      });
      loadContracts(signer, setSoRekt);
    } catch (err) {
      console.log(err);
    }
  } else {
    // alert('Metamask not installed or conflicted with other wallets');
    // setTitle("Oops!");
    setMsg(
      "Yo, you ain't got Metamask installed, bro. Gotta get that sh*t first to proceed."
    );
    setIsModalOpen(true);
    findBrowserAndSetNavigation(setNavigation);
    // setButtonText("Install Metamask");
    return;
  }
}
const loadContracts = async (signer, setSoRekt) => {
  const soRekt = new ethers.Contract(
    SoRektAddress.address,
    SoRektAbi.abi,
    signer
  );
  setSoRekt(soRekt);
  console.log(soRekt);
  let symbol = await soRekt.symbol();
  console.log(symbol);
  // console.log("Aftet");
};
const handleSwitchNetworkBsc = async () => {
  console.log('in swtich net');
  try {
    let chainId = await window.ethereum.request({ method: 'eth_chainId' });
    chainId = parseInt(chainId.toString(), 16);
    console.log('Chain id: ', chainId);
    if (chainId !== 97) {
      // setIsModalOpen(true);
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: '0x61',
            chainName: 'BSC Testnet',
            rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
            blockExplorerUrls: ['https://explorer.binance.org/smart-testnet'],
            nativeCurrency: {
              symbol: 'BNB',
              decimals: 18,
            },
          },
        ],
      });
    }
    chainId = await window.ethereum.request({ method: 'eth_chainId' });
    chainId = parseInt(chainId.toString(), 16);
    console.log('Chain id: ', chainId);
    if (chainId !== 97) {
      console.log('xxx');
      // setMsg("We are currently available on BSC network only. Kindly switch to Binance Smart Chain (BSC) network.");
      // setTitle("Oops!");
      // setIsModalOpen(true);
      // switchNetwork();
      return 0;
    }
    return 1;
  } catch (err) {
    console.log(err);
    return 0;
  }
};
export async function web3HandlerBsc(
  setAccount,
  setProvider,
  setSoRektAi,
  setMsg,
  // setButtonText,
  setIsModalOpen
) {
  console.log(window.ethereum);
  if (
    window.ethereum &&
    window.ethereum.isMetaMask &&
    !window.ethereum.isBraveWallet
  ) {
    // let chainId;
    try {
      await handleSwitchNetworkBsc();
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      });
      setAccount(accounts[0]);
      console.log("account: ",accounts[0]);
      // Get provider from Metamask
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      setProvider(provider);
      // Set signer
      const signer = provider.getSigner();

      window.ethereum.on('chainChanged', (chainId) => {
        // window.location.reload();
      });

      window.ethereum.on('accountsChanged', async function (accounts) {
        setAccount(accounts[0]);
        await web3HandlerBsc(
          setAccount,
          setProvider,
          setSoRektAi,
          setMsg,
          // setButtonText,
          setIsModalOpen
        );
      });
      const sorektAI=await loadAiContract(setSoRektAi, signer);
      return [sorektAI,accounts[0]];;
    } catch (err) {
      console.log(err);
      setMsg("Error in connecting waller");
      setIsModalOpen(true);
      return false;
    }
  } else {
    console.log('no metamask');
    setMsg('Metamask not installed or conflicted with other wallets');
    setIsModalOpen(true);
    findBrowserAndSetNavigation();
    // setButtonText('Install Metamask');
    return false;
    
  }
  // console.log('Acc :', account);
  // swal('Successfully connected', '', 'success');
}
async function loadAiContract(setSoRektAi, signer) {
  const soRektAi = new ethers.Contract(
    SoRektAiAddress.address,
    SoRekAitAbi.abi,
    signer
  );
  setSoRektAi(soRektAi);
  console.log("sorekt ai contract:",soRektAi);
  let symbol = await soRektAi.symbol();
  console.log(symbol);
  return soRektAi;
}
